import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react"
import Layout from "../components/layout";
import Seo from "../components/seo";
import PeopleList from "../components/peopleList";
import PeopleTitle from "../components/peopleTitle";
import imgDiversity from "../images/diversity.svg";
import imgDiversityIcon01 from "../images/diversityIcon01.svg";
import imgDiversityIcon02 from "../images/diversityIcon02.svg";
import imgDiversityIcon03 from "../images/diversityIcon03.svg";
import peopleData from "../data/advisoryBoardMembers.json";

const Diversity = ({ data }) => {
  const { homepage } = data.siteMetaData;

  return (
    <Layout {...homepage}>
      <link href="https://fonts.googleapis.com/css?family=Arvo:400,700&display=swap" rel="stylesheet"/>

      <Seo title="MISSION" />
      <div css={container}>

        <div css={section}>
          <h1 css={title}>WHAT IS "MASHING UP" ?</h1>
          <p css={subTitle}>MASHING UP＝インクルーシブな未来を拓く、メディア＆コミュニティ。</p>
          <p css={text}>MASHING UPは、多様な視点で社会課題を捉え未来を考えるプロジェクトです。イベントやメディアを通じ、性別、業種、世代、国籍を超え多彩な人々と対話を深め、これからの社会や組織のかたち、未来のビジネスを考えていきます。またSDGsゴールで表明される持続可能な開発目標に賛同する人々をサポートし、真にインクルーシブな社会づくりに貢献していきたいと考えています。</p>
          <div css={img}>
            <img src={imgDiversity} alt="" />
          </div>
        </div>

        <div css={section}>
          <PeopleTitle
            text="ADVISORY BOARD MEMBERS"
          />
          <PeopleList
            data={peopleData}
          />
        </div>

        <div css={section}>
          <h1 css={title}>OUR MISSION</h1>
          <p css={subTitle}>私たちが目指すこと</p>
          <p css={text}>多彩な視点から課題をとらえて持続可能な社会を目指し、多様な個人が尊重しあえるインクルーシブな環境づくりに貢献します。</p>
          <div css={box}>
            <p css={boxIcon}><img src={imgDiversityIcon01} alt="" /><br/>個人</p>
            <p css={boxText}>多様な人材がそれぞれの背景にかかわらず置かれた環境で尊重され、自分らしいキャリアを形成できる状態をめざします。</p>
            <p css={boxIcon}><img src={imgDiversityIcon02} alt="" /><br/>企業</p>
            <p css={boxText}>個の能力が発揮されイノベーションが起こりやすい企業風土づくりへの貢献をめざします。</p>
            <p css={boxIcon}><img src={imgDiversityIcon03} alt="" /><br/>社会</p>
            <p css={boxText}>ダイバーシティを促進しインクルーシブで持続可能な社会形成の一助となることをめざします。</p>
          </div>
        </div>

        <div css={section}>
          <h2 css={title}>OUR VALUE</h2>
          <p css={subTitle}>Open & Positive.</p>
          <p css={subTitle}>多様な価値観を受け入れ、ポジティブなアイディアを発信します。</p>
        </div>

        <div css={section}>
          <h2 css={title}>OUR CONTENTS</h2>
          <p css={subTitle}><a css={subTitle} href="https://www.businessinsider.jp/mashingup/" target="_blank" rel="noreferrer">メディア</a></p>
          <p css={text}>ビジネス インサイダー ジャパン配下のバーティカルメディアで、<br/>DEI分野にフォーカスした情報をお届けします。</p>
          <p css={subTitle}>イベント</p>
          <p css={text}>DEIやESGをテーマにしたビジネスカンファレンスやセミナーを展開します。<br/><a href="/people/">PAST SPEAKERS</a></p>
        </div>

      </div>
    </Layout>
  );
};
export default Diversity;

const container = css`
  width: clamp(748px, 80%, 2000px);
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 94%;
    display: block;
    padding: 50px 0 0;
  }
`
const section = css`
  margin-bottom: 120px;
  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
`
const title = css`
  font-family: Arvo, sans-serif;
  text-align: center;
  font-size: 3rem;
  font-weight: bold;
  line-height: 1;
  margin: 20px 0;
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
`
const subTitle = css`
  text-align: center;
  font-size: 1.25rem;;
  font-weight: bold;
  margin: 20px 0;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    font-size: 0.875rem;
  }
`
const text = css`
  font-size: 0.875rem;
  text-align: center;
  a {
    font-size: 0.875rem;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  @media (max-width: 767px) {
    font-size: 0.9375rem;
  }
`
const img = css`
  text-align: center;
  margin: 50px 0;
  img {
    width: 900px;
  }
  @media (max-width: 767px) {
    img {
      width: 100%;
    }
  }
`
const box = css`
  margin: 55px auto;
  padding: 0 0 40px;
  border: solid 2px #000;
  width: 790px;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const boxIcon = css`
  margin: 40px 0 10px;
  text-align: center;
  line-height: 150%;
  @media (max-width: 767px) {
  font-size: 1.125rem;
  }
`
const boxText = css`
  padding: 0 75px;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 180%;
  @media (max-width: 767px) {
    padding: 0 20px;
    font-size: 0.875rem;
  }
`

export const query = graphql`
  {
    siteMetaData: site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
